import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Home from './pages';
import Download from './pages/download';
import Nav from './component/navbar/navbar.js'
import { createBrowserHistory } from "history";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

import NotoSansThai from './assets/fonts/NotoSansThai.ttf';


const hist = createBrowserHistory();

const themeLight = createTheme({
	palette: {
		background: {
			default: "#F5F5F7"
		},
		text: {
			primary: "#303C46",
			fontFamily: `sans-serif`,
		}
	},
	typography: { fontFamily: 'Prompt' },
	components: {
		MuiCssBaseline: {
			styleOverrides: `
			@font-face {
			  font-family: 'Prompt';
			  font-style: normal;
			  font-display: swap;
			  font-weight: 400;
			  src: local('Prompt'), local('Prompt-Regular'), url(${NotoSansThai}) format('ttf');
			  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
			}
		  `,
		},
	},
});

const themeDark = createTheme({
	palette: {
		background: {
			default: "#222222"
		},
		text: {
			primary: "#ffffff",
			fontFamily: `sans-serif`,
		}
	},
	typography: { fontFamily: 'Prompt' },
	components: {
		MuiCssBaseline: {
			styleOverrides: `
			@font-face {
			  font-family: 'Prompt';
			  font-style: normal;
			  font-display: swap;
			  font-weight: 400;
			  src: local('Prompt'), local('Prompt-Regular'), url(${NotoSansThai}) format('ttf');
			  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
			}
		  `,
		},
	},
});

function App() {
	const [light, setLight] = React.useState(false);

	return (
		<Router>
			<ThemeProvider theme={!light ? themeLight : themeDark}>
				{/* <Button onClick={() => setLight((prev) => !prev)}>Toggle Theme</Button> */}
				<Nav />
				<Routes history={hist}>
					<Route exact path='/' element={<Home />} />
					<Route path='/pm25' element={<Home />} />
					<Route path='/download' element={<Download />} />
					{/* <Route path="*" element={<Navigate replace to="/" />} /> */}
				</Routes>
			</ThemeProvider>
		</Router>
	);
}

export default App;
